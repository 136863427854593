.main-content {
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.image-ico.settings {
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
}

.settings-button-container {
  width: 254pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-button {
  width: 150px;
  height: 50px;
  background-color: darkgray;
  border: none;
}

.background-image-settings {
  background-size: contain;
  background-image: url(../../assets/background_home.png);
  width: 254pt;
  height: 86pt;
}
