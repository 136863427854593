.container {
  height: 100vh;
  position: relative;
}

.logo {
  background-size: contain;
  background-image: url(../../assets/qrvox_home.png);
  width: 200pt;
  height: 70pt;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.background-image {
  background-size: contain;
  background-image: url(../../assets/background_home.png);
  width: 254pt;
  height: 86pt;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scan-button {
  background-color: transparent;
  border: 0;
  width: 70pt;
  height: 70pt;
  position: absolute;
  left: 50%;
  bottom: -30pt;
  transform: translate(-50%, -50%);
  outline: none;
}

.tab-bar {
  position: absolute;
  height: 40pt;
  width: 100vw;
  bottom: 0;
}

.close-button {
  position: absolute;
  top: 10pt;
  left: 10pt;
  width: 24pt;
  height: 24pt;
  background-color: transparent;
  background-size: contain;
  background-image: url(../../assets/icon_close.png);
  border: 0;
}

.history-button {
  position: absolute;
  top: 10pt;
  right: 10pt;
  width: 24pt;
  height: 24pt;
  background-color: transparent;
  background-size: contain;
  background-image: url(../../assets/ico_history.png);
  border: 0;
}

.nav-title {
  color: #fff;
  text-align: center;
  line-height: 30pt;
  margin-top: 7pt;
}

.hidden-player-close-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
}

.player-loader {
  border: 12px solid #f3f3f3;
  border-top: 12px solid #32c8ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* animation: spin 1s linear infinite; */
  animation: spin 1s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.play-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.navbar-default {
  position: absolute;
  top: 0;
  left: 0;
  height: 44pt;
  width: 100vw;
  background-color: #32c8ff;
}

.image-ico {
  object-fit: contain;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.subheader {
  text-align: center;
}

.button-text {
  color: #fff;
}
